import { FormatNumberBrowserLocalePipe } from '@novisto/common';
import isNil from 'lodash/isNil';

import {
  EmbedderValue,
  EmbedderValueField,
  FileValue,
  FormatOptions,
  isChoiceValueType,
  MinimalDocumentMetaData,
  OptionList,
  Value,
  ValueDefinitionType,
} from '../models';

export class ValueUtils {
  public static displayExplanation(value: Value, optionLists: Record<string, OptionList>): boolean {
    switch (value.type) {
      case ValueDefinitionType.boolean:
        return value.type_details[`prompt_on_${value.value.value}`];
      case ValueDefinitionType.choice:
        if (isChoiceValueType(value) && !value.type_details.selection_set_apply_all) {
          const optionList = optionLists[value.type_details.selection_set_id || ''];
          const items = optionList?.option_list_items || optionList?.selection_set_items || [];

          return items.some((i) => i.display_explanation && value.value?.values?.includes(i.name));
        }

        return value.type_details.display_explanation;
      default:
        return false;
    }
  }

  public static displayUnits(value?: Value): boolean {
    return (
      value &&
      [ValueDefinitionType.calculated, ValueDefinitionType.number].includes(value.type) &&
      value.type_details.units &&
      value.type_details.units !== 'default'
    );
  }

  public static formatValue(
    formatNumberBrowserLocalePipe: FormatNumberBrowserLocalePipe,
    embedderValue: EmbedderValue,
    field: EmbedderValueField,
    fieldId?: string,
    documents: Record<string, MinimalDocumentMetaData> = {},
    formatOptions: FormatOptions = {},
  ): { value: string | string[]; html: boolean } {
    const value = embedderValue.value;
    let formattedValue = '';
    let html = false;

    if (value && !isNil(value?.value)) {
      switch (value.type) {
        case ValueDefinitionType.choice:
          if (field === EmbedderValueField.explanation) {
            formattedValue = value.value?.additional_text;
          } else {
            formattedValue = value.value.values.length ? value.value.values : '';
          }
          break;

        case ValueDefinitionType.boolean:
          if (field === EmbedderValueField.explanation) {
            formattedValue = value.value?.additional_text;
          } else {
            formattedValue = value.type_details[`label_${value.value.value}`];
          }
          break;

        case ValueDefinitionType.calculated:
        case ValueDefinitionType.number:
          if (formatOptions.plainNumber) {
            formattedValue = value.value;
          } else {
            formattedValue = formatNumberBrowserLocalePipe.transform(
              String(value.value),
              Number(value.type_details.max_decimals ?? 0),
            );
          }
          break;

        case ValueDefinitionType.file_v2:
          const document: FileValue = value.value.find((v: { file_id: string }) => v.file_id === fieldId);

          if (fieldId && document) {
            if (field === EmbedderValueField.value && documents[fieldId]) {
              formattedValue = `${documents[fieldId].name}.${documents[fieldId].extension}`;
            } else if (field === EmbedderValueField.url) {
              formattedValue = document.url || '';
            } else if (field === EmbedderValueField.page) {
              formattedValue = String(document.page_number || '');
            } else if (field === EmbedderValueField.explanation) {
              formattedValue = document.explanation || '';
            }
          }
          break;

        default:
          html = Boolean(value.type_details.rich_text);
          formattedValue = value.value;
          break;
      }
    }

    return { html, value: this.fetchValue(formattedValue) };
  }

  private static fetchValue(value?: string | string[] | null): string | string[] {
    return value || '[No value]';
  }
}
